<template>
  <v-app-bar
    id="app-bar"
    fixed
    app
    style="background: linear-gradient(99deg, #183B94 55.98%, #549ACC 110.85%), #183B94"
    height="57"
  >
    <v-btn
      elevation="0"
      class="mr-4"
      dark
      icon
      small
      @click="modificaMenu"
    >
      <v-icon v-if="icone">
        mdi-view-quilt
      </v-icon>
      <v-icon v-else>
        mdi-dots-vertical
      </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down"
      style="color: white"
      v-text="$route.meta.title"
    />

    <v-spacer />
    <base-selecao-tecnico v-if="this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && this.$store.state.user.roles[0].slug === 'tecnico' && (this.$route.path.includes('loja') || this.$route.path.includes('volumes') || this.$route.path.includes('qualidades'))" />
    <base-selecao-produtor v-if="this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && this.$store.state.user.roles[0].slug === 'produtor'" />
    <base-notification-pessoa v-if="!$vuetify.breakpoint.xs && this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && this.$store.state.user.roles[0].slug === 'produtor'" />
    <base-notification-pessoa v-if="!$vuetify.breakpoint.xs && this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && this.$store.state.user.roles[0].slug === 'tecnico'" />
    <base-carrinho v-if="!$vuetify.breakpoint.xs && this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && (this.$store.state.user.roles[0].slug === 'tecnico' || this.$store.state.user.roles[0].slug === 'produtor') && !this.$route.path.includes('carrinho') && !this.$route.path.includes('pedido')" />
    <base-notification-adm v-if="!$vuetify.breakpoint.xs && this.$store.state.user && this.$store.state.user.roles && this.$store.state.user.roles.length > 0 && (this.$store.state.user.roles[0].slug === 'gestor' || this.$store.state.user.roles[0].slug === 'supervisor')" />
    <base-menu-profile v-if="!$vuetify.breakpoint.xs" />
    <v-btn
      v-if="!$vuetify.breakpoint.xs"
      icon
      small
      color="white"
      title="Sair do portal"
      class="card-icon mx-2"
      @click="mensagem"
    >
      <v-icon>
        mdi-logout
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
// Utilities
  import { mapState, mapMutations } from 'vuex'
  import { logout } from '@/services/AuthService'
  import Swal from 'sweetalert2'

  export default {
    name: 'DashboardCoreAppBar',

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return { icone: false }
    },

    computed: {
      ...mapState(['drawer']),
    },

    created () {
      this.icone = this.value
    },

    methods: {
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
      modificaMenu () {
        this.setDrawer(!this.drawer)
        this.icone = !this.icone
      },
      mensagem () {
        if ((this.$store.state.user.roles[0].slug === 'tecnico' || this.$store.state.user.roles[0].slug === 'produtor') && this.$store.state.carrinho.produtos.length > 0) {
          Swal.fire({
            title: 'Atenção!',
            html: 'Ao sair da plataforma os produtos contidos<br/> no seu carrinho serão excluídos.<br/><br/> Tem certeza que deseja sair?',
            icon: 'warning',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonColor: '#109010',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            focusConfirm: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.sair()
            }
          })
        } else {
          Swal.fire({
            title: 'Tem certeza que deseja sair?',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#109010',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sair',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
            focusConfirm: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.sair()
            }
          })
        }
      },
      sair () {
        logout()
      },
    },
  }
</script>
